<!-- Importacion Presupuestos Funerarias a Presupuestos Expedientes -->

<template>
  <div class="funes_pres_import_F" v-if="schema">
    <base_Header
      v-if="Entorno.header.header"
      :Entorno="Entorno.header"
      @onEvent="event_Header">    
    </base_Header>

    <v-sheet :elevation="4">
      <!-- Contenido -->
      <div class="contenedor">
        <!-- Filtro de Busqueda -->
        <div class="conflex" style="width:80%">
        
            <v-select style="flex: 0 0 100px"
              v-bind="$select"
              v-model="schema.ctrls.ano.value"
              :items="itemsAno"
              :label="schema.ctrls.ano.label"
              item-value="ano"
              item-text="label"
              @change="get_records('ini')"
              :disabled="disabledAno"
            >
            </v-select>
          
            <v-select 
              v-bind="$select"
              v-model="schema.ctrls.descrip.value"
              :items="filtroPresupuestos"
              label="Tipo presupuesto"
              item-value="id"
              item-text="label"
              @change="get_records('ini')"
            ></v-select>

          
            <v-select 
              v-bind="$select"
              v-model="schema.ctrls.cia_id.value"
              :items="itemsCia"
              :label="schema.ctrls.cia_id.label"
              item-value="id"
              item-text="label"
              @change="get_records('ini')"
              :disabled="disabledCia"
            >
            </v-select>
    
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <!-- <template v-slot:top_btns="{ Sitem }">
            <div class="conflex" style="margin:10px 0 0 10px">
  
              <btracombi 
                v-if="!is_padreEdit"
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion: $event.accion, row: Sitem })">
              </btracombi>

            </div>

          </template> -->

          <!-- Slot campos grid menos el de acciones -->
          <template v-slot:fields_grid="{ Sitem, Sheader }">
            {{ get_fieldGrid(Sitem, Sheader) }}
          </template>

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones_grid="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion: $event.accion, row: Sitem })"
            >
            </btracombi>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");
const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");
const btracombi = () => plugs.groute("btracombi.vue", "comp");

export default {
  mixins: [mixinFinder],
  components: { base_Header, base_Fgrid, btracombi },
  props: {
    padre: { type: String, default: "" },
    id: { type: [Number, String], default: 0 },
    componenteTipo: { type: String, default: "FC" },
    disparoFinder: { type: Boolean, default: false },

    // si recibo datos para filtrar por cia, año o lo que sea
    filtroImportServicio:{},

    Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
  },

  data() {
    return {
      schema: null,
      api: "funes_pres_F",
      stName: "stFfunes_pres_import",
      component_M: null,
      modal: false,
      accion: "",
      accionRow: {},
      disparo: false,

      headers: [],
      ST_tipo_presup: null,
      itemsAno: null,
      itemsCia: null,
      itemsDescripcion: null,
      
      // Permitir cambiar de cia en filtro
      disabledCia:false,
      disabledAno:false
    };
  },

  methods: {
    ini_data() {
      console.log("DEV " + this.api + " ********************** (ini_data)");

      // pendiente: por que aqui y no en la base??????
      // guardo Schema del store en variable local
      this.schema = this.$store.state[this.stName].schema;
      console.log('SCHEMAA: ', this.stName, this.schema);
      // obtengo items tipo presupuestos
      this.ST_tipo_presup = this.$store.state.datos_iniciales.tipo_presup;
      this.filtroPresupuestos=this.$store.state.datos_iniciales.filtroPresupuestos;
      // configuro Entorno
      this.Entorno.grid.autoload = true;
      this.Entorno.header.titulo= "Importación de Presupuestos";
      
      
      this.headers = {
        header: [
          { text: "AÑO", value: "ano" },
          { text: "CIA", value: "xcia" },
          { text: "TIPO", value: "name_id_presciac" },
          { text: "MUNICIPIO", value: "atlas" },
          { text: "PFT", value: "preferente", style:"color:green;font-weight: bold"},
          { text: "DESCRIPCIÓN", value: "descrip" },

          // { text: "AÑO ANT.", value: "imp_ant" },
          // { text: "DIF %", value: "dif" },
          { text: "P.DIRECTO", value: "sup_pd" },
          // { text: "IMP.CIA", value: "imp_cia" },
          { text: "IMPORTE", value: "imp" },
         
          {
            text: "ACCIONES",
            value: "acciones",
            sortable: false,
            width: "auto"
          }
        ]
      };
      this.itemsAno = [
        { id: "", ano: "", label: "TODOS" },
        { id: "1", ano: 2019, label: "2019" },
        { id: "2", ano: 2020, label: "2020" },
        { id: "3", ano: 2021, label: "2021" }
      ];
      this.itemsCia = [
        { id: "", label: "TODAS LAS CIAS" },
        { id: "83", label: "MAPFRE" },
        { id: "50165", label: "KUTXABANK" },
       // { id: "50508", label: "KUTXABANK" }
      ];
  

      // Inicializo cabeceras
      this.Entorno.grid.headers = this.set_headers();     
    },

    //
    ini_schemaCtrls() {
      this.schema.ctrls.ano.value = new Date().getFullYear();

      //Inicializar filtro si se envia para servicios de expedientes      
      if (this.filtroImportServicio) {
        this.schema.ctrls.cia_id.value=this.filtroImportServicio.cia_id?this.filtroImportServicio.cia_id:''
        this.disabledCia=true
        this.disabledAno=true
      }
      
    },

    //
    row_dblclick(evt) {
      if (this.is_padreEdit) {
        this.$root.$alert.open("Cancele o Guarde para acceder", "info");
        return;
      }
      this.reactividad_M({
        accion: this.Entorno.grid.row.dblclick,
        row: evt.row
      });
    },

    // Campos personalizados GRID
    //
    get_fieldGrid(item, header) {
      switch (header.value) {
        case "dif":
          return this.get_dif(item);

        // case "id_presciac":
        //   return this.get_presciac(item);

        case "preferente":
          return item["preferente"] === "1" ? "P" : "";

        default:
          return item[header.value];
      }
    },

    //
    //pendiente: aplicar el mismo que en el mx
    get_dif(item) {
      // Devuelvo % de la diferencia entre importe e importe año anterior
      let imp_ant = Number(item.imp_ant);
      let imp = Number(item.imp);
      if (imp <= 0 || imp_ant <= 0) return "";
      return parseFloat(((imp - imp_ant) / imp_ant) * 100).toFixed(2);
    },

    
    // pendiente:
    // lo deberia de devolver la sql con un join en la carga de registros
    //  Devuelvo el nombre del tipo de presupuesto
    get_presciac(item) {
      const result = this.ST_tipo_presup.find(
        elem => elem.id === item.id_presciac
      ).name;
      return result == undefined ? "" : result;
    },
    // recargar finder
    reload() {
      //this.get_records(this.Entorno.grid.autoload? 'ini' : '');
    },

    extraer(evt) {      
      this.$emit('onEvent', evt);
    },
    
  },
    
    // computed: {
    // informacion para utilidades de presupuestos operaciones automaticas
    //   data_util(){
      
    //      let obj={};
    //      obj.ano=this.schema.ctrls.ano.value;
    //      obj.funeraria=this.$store.state[this.padre].record
    //      obj.presupuesto=this.filtroPresupuestos.find(x => x.id == this.schema.ctrls.descrip.value);
    //      obj.cia=this.itemsCia.find(x => x.id == this.schema.ctrls.cia_id.value);
    //      console.log("obj:",obj)
    //      return obj;
    //   }
    // }
};

</script>
